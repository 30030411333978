import { Link, graphql, withPrefix } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Section from "../components/Section";

function BlogIndex({ data: { blogs, wpBlogs } }) {
  return (
    <main>
      <Section align="center" className="blog-index">
        <h1>Purepost Blog</h1>
        {blogs.edges.map((edge) => (
          <li key={edge.node.id} className="horizontal-stack">
            <div id="blog-index-blurb">
              <a href={withPrefix(edge.node.path)}>{edge.node.name}</a>
              <p className="mb-0">
                {edge.node.childMdx.excerpt.replace(
                  new RegExp(`${edge.node.name}.?s?`),
                  ""
                )}
              </p>
              <p className="font-small font-bold font-subtle">
                {edge.node.date}
              </p>
            </div>
          </li>
        ))}
        {wpBlogs.edges.map((edge) => (
          <li key={edge.node.id} className="horizontal-stack">
            {edge.node.frontmatter.cover && (
              <GatsbyImage
                image={getImage(
                  edge.node.frontmatter.cover.childImageSharp.gatsbyImageData
                )}
              />
            )}
            <div id="blog-index-blurb">
              <a href={withPrefix(`/blog${edge.node.fields.slug}`)}>
                {edge.node.frontmatter.title}
              </a>
              <p className="mb-4">{edge.node.excerpt}</p>
              <p className="font-small font-bold font-subtle">
                {edge.node.frontmatter.date}
              </p>
            </div>
          </li>
        ))}
      </Section>
    </main>
  );
}

export default BlogIndex;

export function Head() {
  console.log(process.env.GATSBY_STAGING);
  const noTrack = process.env.GATSBY_STAGING === true ? (
    <meta name="robots" content="noindex, nofollow" />
  ) : (
    <meta />
  );
  return <>{noTrack}</>;
}

export const query = graphql`
  query {
    blogs: allGoogleDocs(
      filter: { breadcrumb: { elemMatch: { name: { eq: "Blog" } } } }
    ) {
      edges {
        node {
          id
          name
          path
          childMdx {
            excerpt(pruneLength: 300)
          }
          date(formatString: "LL")
        }
      }
    }
    wpBlogs: allMdx(
      filter: { fileAbsolutePath: { regex: "//wp-blog//" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          frontmatter {
            title
            date(formatString: "LL")
            cover {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;
